import React from 'react';
import {Link} from "react-router-dom";

interface ButtonProps {
  children: JSX.Element | string;
  type: 'button' | 'navigation' | 'submit' | string;
  variant?: 'solid' | 'text' | 'outlined' | 'disabled';
  to?: string;
  onClickFunction?: () => void;
  disabled?: boolean;
}

export default function Button({
  children,
  type,
  variant,
  to,
  onClickFunction,
}: ButtonProps) {
  const variants = {
    solid:
      'w-full inline-block bg-blue-30 text-yellow-90 text-center font-bold text-base px-6 py-2 rounded-[100px]',
    text: 'w-full inline-block bg-transparent text-blue-30 text-center font-bold text-base px-6 py-2 rounded-[100px]',
    outlined:
      'w-full inline-block bg-transparent border-2 border-blue-30 text-blue-30 text-center font-bold text-base px-6 py-2 rounded-[100px]',
    disabled:
      'w-full inline-block bg-blue-30 text-yellow-90 text-center font-bold text-base px-6 py-2 rounded-[100px] opacity-50 cursor-not-allowed',
  };

  if (type === 'navigation' && to) {
    return (
      <Link className={variants[variant || 'solid']} to={to}>
        {children}
      </Link>
    );
  }

  if (type === 'submit') {
    return (
      <button type='submit' className={variants[variant || 'solid']}>
        {children}
      </button>
    );
  }

  return (
    <button
      type='button'
      onClick={onClickFunction}
      className={variants[variant || 'solid']}
    >
      {children}
    </button>
  );
}
