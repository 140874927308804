
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';

export const initializeDatadogRUM = () => {
  if (
    process.env.NEXT_PUBLIC_DD_APPLICATION_ID &&
    process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN &&
    process.env.NEXT_PUBLIC_SST_DOMAIN_NAME
  ) {
    const rumConfig: RumInitConfiguration = {
      applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'dmpag_checkout_v2',
      env: 'prod',
      version: '1.0.1',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      trackSessionAcrossSubdomains: true,
      enableExperimentalFeatures: ['clickmap'],
      allowedTracingUrls: [
        (url) => url.startsWith(process.env.NEXT_PUBLIC_SST_DOMAIN_NAME!),
      ],
    };
    datadogRum.init(rumConfig);
    datadogRum.startSessionReplayRecording();
  }
};
