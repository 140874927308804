import React, { Suspense } from 'react';

import { DatadogRum } from '../components/datadogRum';


export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang='PT-BR'>
      <head>
        <style
          id='stitches'
        />
      </head>
      <body className={`overflow-x-hidden`}>
          <Suspense>
            <DatadogRum />
          </Suspense>
          {children}
      </body>
    </html>
  );
}
