
import React from 'react';

import Header from '../../components/Header';
// @ts-ignore
import errorImg from '../../assets/error-img.png';
import RootLayout from "../layout";

export default function Expiredlink() {
  return (
    <RootLayout>
      <Header />
      <div className='mt-36'>
        <div className='flex items-center justify-center'>
          <img
            src={errorImg}
            alt='imagem de mão segurando celular apresentando erro'
            width={250}
            height={300}
          />
        </div>
        <div className='mt-12'>
          <p className='text-center text-2xl font-bold tracking-wide text-[#202AD0]'>
            <span>Ops! Tivemos um erro.</span>
          </p>
          <p className='text-center font-medium text-white'>
            Não encontramos a página que você está procurando.
          </p>
        </div>
      </div>
    </RootLayout>
  );
}
