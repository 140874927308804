
/* eslint-disable */
// @ts-nocheck
import React, { useEffect } from 'react';
import helpImg from "../assets/help-icon.svg";


export default function IntercomChat({ appId }: { appId: string }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      window.Intercom('boot', {
        app_id: '${appId}',
      })
    `;
    document.body.appendChild(script);

    return () => {
      if (typeof window !== 'undefined') {
        const script2 = document.createElement('script');
        script2.innerHTML = `
        window.Intercom('shutdown')
      `;
        document.body.appendChild(script2);
      }
    };
  }, [appId]);

  useEffect(() => {
    typeof window !== 'undefined';
  }, []);

  const openIntercom = () => {
    if (typeof window !== 'undefined') {
      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.Intercom('show')
      `;
      document.body.appendChild(script2);
    }
  };

  return (
    <button onClick={() => openIntercom()}>
      <img src={helpImg} alt='' width={24} height={24} />
    </button>
  );
}
