import React, {useEffect} from 'react';
import {HashRouter, Route, Routes} from "react-router-dom";
import Expiredlink from "./pages/error/page";
import PaymentLink from "./pages/links/page";
import './globals.css';
import  {OIDCWrapper} from "./pages/analysis/page";


function App() {
  useEffect(() => {
    if(window.location.hash === "") {
      window.location.href = "#";
    }
  });

  return (
      <HashRouter>
        <Routes>
          <Route path={"analysis/:linkID"} element={<>
            <OIDCWrapper/>
          </>
          }>
          </Route>
          <Route path={"error"} element={
            <Expiredlink/>
          }>
          </Route>
          <Route path={"links/:linkID"} element={
            <PaymentLink/>
          }></Route>
          <Route path={"*"} element={
            <Expiredlink/>
          }/>
        </Routes>
      </HashRouter>
  );
}

export default App;
