import React, { useEffect } from 'react';

import { initializeDatadogRUM } from '../utils/datadog';

export function DatadogRum() {
  useEffect(() => {
    initializeDatadogRUM();
  }, []);
  return <></>;
}
