// @ts-ignore
import axios from "axios";
import { Environment } from "../utils/environment";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://gateway.dmpag.net/v1",
});

export const fetchLink = async(linkID: string) => {
  return api.get(`/external_payments/payment-links/${linkID}`,
  {
    headers: {
      'Accept': '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
      'Content-Type': 'application/json',
    },
  });
}

export interface PayRequest {
  user: Record<string, any>
  link: Record<string, string>;
  account_id: string;
  organization_id: string;
}

export const fetchPay = async (link: PayRequest, token: string): Promise<any> => {
  let payload = {
    customer: link.user,
    link: link.link,
    merchant: {
      id: link.link.organization_id,
      document: {},
    }
  }

  try {
    const response = await api.post(Environment.apiPaymentsURL, payload, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'Content-Type': 'application/json',
      },
    });

    const data = response.data;

    if (data['checkout_url'] !== undefined && data['checkout_url'].status !== '') {
      return response.data;
    } else {
      return {
        error: "Something went wrong",
      };
    }
  } catch (error) {
    console.error('Error:', error);
    return {
      error: "Something went wrong",
    };
  }
};
