import React from 'react';

// @ts-ignore
import loadingImage from "../../assets/analysis-img.png";

export default function LoadingModal() {
  return (
    <div className='absolute z-10 flex h-screen w-screen flex-col items-center justify-center bg-lime-950/10'>
      <div className='m-auto flex h-[400px] w-[300px] flex-col items-center justify-start gap-5 rounded-lg bg-blue-30 px-4 py-6 text-center text-white'>
        <h1 className='text-3xl'>Carregando...</h1>

        <h4 className='text-base'>
          Aguarde um pouquinho, estamos preparando tudo pra você.
        </h4>
        <img src={loadingImage} alt='loading' />
      </div>
    </div>
  );
}
