
import React from 'react';
import {Link} from "react-router-dom";

// @ts-ignore
import closeIcon from "../../assets/close-icon.svg";
// @ts-ignore
import logo from "../../assets/logo-dmpag.svg";
// @ts-ignore
import bagIcon from "../../assets/bag-icon.svg"
import IntercomChat from "../IntercomChat";
interface PropTypes {
  purchaseDescription?: {
    value: string;
    storeName: string;
  };
}

export default function Header({ purchaseDescription }: PropTypes) {

  return (
    <>
      <header className='flex w-full flex-col items-center gap-2 bg-blue-30 p-4'>
        <div className='flex w-full items-center justify-between'>
          <Link to='https://www.vocedm.com.br/portal/dmpag'>
            <img
              src={closeIcon}
              alt=''
              width={16}
              height={14}
              className='cursor-pointer'
            />
          </Link>
          <img
            src={logo}
            alt=''
            width={85}
            height={40}
            className='relative left-1'
          />
          <IntercomChat appId={'rdnpw0vx'} />
        </div>
        {purchaseDescription && (
          <div className='flex w-full flex-col items-center gap-2 rounded-b-lg bg-blue-30 px-4 py-3 text-white'>
            <img
              src={bagIcon}
              alt=''
              width={40}
              height={40}
              className=''
            />
            <p className='text-2xl font-bold'>R$ {purchaseDescription.value}</p>
            <p className='font-thin'>
              Compra na {purchaseDescription.storeName}
            </p>
          </div>
        )}
      </header>
    </>
  );
}
